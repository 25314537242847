import React from 'react';
import '../../App.css';
import sections from '../Data/Data.json'

import image_a from '../../img/Yasser.jpg'
import image_b from '../../img/ibra-1.jpg'
import image_c from '../../img/awad-1.jpg'
import image_d from '../../img/abu-Ali.jpg'
import image_e from '../../img/Ali.jpg'
import Footer from '../Footer/Footer';


const About = () => {
  return (
    <>
      <div className="about">
          <p className='header__qq'> جمعية صناع السعادة </p>
          <p className='header__q'> جمعية صناع السعادة للتنمية الاجتماعية بالخبر ترخيص رقم - 5100 
          مركز متخصص في صناعة السعادة المجتمعية وبث الطمأنينة عبر برامج وخدمات ومبادرات ذات معايير مهنية وجودة عالية الأداء
          تتناول جميع مجالات الحياة للفرد والمجتمع. </p>
      </div>

      <section className="section_a">
        <div className="grid-container">
           <div className="left-panel">
            <div className="sections-container">
                   {sections.data_one.map(section => (
                   <div key={section.title} className="section">
                       <h2>{section.title}</h2>
                       <p>{section.content}</p>
                   </div>
                       ))}
              </div>   
           </div>
           <div className="right-panel">
           <div className="sections-container">
                   {sections.data_tow.map(section => (
                   <div key={section.title} className="section">
                       <h2>{section.title}</h2>
                       <p>{section.content}</p>
                   </div>
                       ))}
               </div>               
           </div>           
       </div>
      </section>
      <section className="section_b" >
        <h2 className='header-section'>مجلس إدارة الجمعية</h2>
        <div className="about-card">
          
          <div className="card-staff">
            <img src={image_a} alt="صورة" className="card__image" />

            <h2 className="card__name">د.ياسر آل داشل</h2>
            <p className="card__job">رئيس مجلس الإدارة</p>
          </div>

          <div className="card-staff">
            <img src={image_b} alt="صورة" className="card__image" />
            <h2 className="card__name">إبراهيم القحطاني</h2>
            <p className="card__job">نائب رئيس مجلس الإدارة</p>
          </div>
          <div className="card-staff">
            <img src={image_c} alt="صورة" className="card__image" />
            <h2 className="card__name">عوض الزبيدي</h2>
            <p className="card__job"> المشرف المالي </p>
          </div>

          <div className="card-staff">
            <img src={image_d} alt="صورة" className="card__image" />
            <h2 className="card__name"> عبدالرحمن القرني </h2>
            <p className="card__job">عضو مجلس إدارة</p>
          </div>
          <div className="card-staff">
            <img src={image_e} alt="صورة" className="card__image" />
            <h2 className="card__name"> علي الخشرمي </h2>
            <p className="card__job">عضو مجلس إدارة</p>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default About