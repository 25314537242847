import React from "react";
import '../../App.css';
import Footer from '../Footer/Footer';
import ProCard from '../ProCard/ProCard';



const Products = () => {
  return (
    <>
      <ProCard />
      <Footer />
    </>
    
  )
}

export default Products
