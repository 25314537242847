import React from 'react';
import './Cards.css';
import CardItem from '../CardItem/CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1 className='headproject'>مشاريعنا</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/prodcast.jpg'
              text='استضافة متخصصين في تطوير الذات'
              label='برودكاست سعادة'
              path='/#'
            />
            <CardItem
              src='images/volli.jpg'
              text='إعداد فرق تطوعية لخدمة المجتمع المحلي'
              label='فرق صناع السعادة'
              path='/#'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/training.jpg'
              text='استثمار الوقت وتحسين جودة الحياة'
              label='أكاديمية سعادة'
              path='/#'
            />
            <CardItem
              src='images/home.jpg'
              text=' مساعدة الأسر ذوي الدخل المنخفض'
              label='منزل سعيد'
              path='/#'
            />
            <CardItem
              src='images/cur.jpg'
              text='تأهيل أبناء وبنات الخبر ذوي الدخل المنخفض
              لسوق العمل'
              label='تمكين'
              path='/#'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
