import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "../ContactForm/ContactForm.css";
import React from "react";

const Beneficiaries = () => {

    const navigate = useNavigate();

    const [userData, setUserData] = useState({
        name: "",
        job: "",
        national_id: "",
        birth_date: "",
        location: "",
        homeType: "",
        roomNum: "",
        familyNum: "",
        commercialRecord: "",
        shop: "",
        socialsalary: "",
        work_address: "",
        salary: "",
        needs: "",
        healthStatus:"",
        phone: "",
        email: "",
        
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUserData((prevUserData) => ({ ...prevUserData, [name]: value }));
    };

    const [isChecked, setIsChecked] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();

        if (isChecked) {
            axios
                .post("https://hm-a.net/api/beneficiaries/add", userData)
                .then(function (response) {

                    Swal.fire({
                        title: "صناع السعادة",
                        text: "تم الإرسال بنجاح وشكرا لتواصلكم",
                        icon: "success",
                        confirmButtonText: "إغلاق",
                        customClass: {
                            title: "my-title-class",
                            container: "my-content-class",
                            confirmButton: "my-confirm-button-class",
                        },
                    }).then(() => {
                        navigate("/");
                    });
                })
                .catch(function (error) {

                    Swal.fire({
                        title: "صناع السعادة",
                        text: "لم يتم إرسال الطلب بنجاح، يُرجى المحاولة في وقت آخر",
                        icon: "error",
                        confirmButtonText: "إغلاق",
                        customClass: {
                            title: "my-title-class",
                            container: "my-content-class",
                            confirmButton: "my-confirm-button-class",
                        },
                    });
                });
        } else {
            Swal.fire({
                title: "صناع السعادة",
                text: "يُرجى الموافقة على الشروط والأحكام",
                icon: "error",
                confirmButtonText: "إغلاق",
                customClass: {
                    title: "my-title-class",
                    container: "my-content-class",
                    confirmButton: "my-confirm-button-class",
                },
            });
        }
    };





    return (
        <div>
            <div className="services">
                <div className="contact-container">
                    <div className="contact-header">
                        <h2 className="headfont"> تسجيل مستفيد جديد </h2>
                        <div className="container">
                            <form className="form-control" onSubmit={handleSubmit}>
                                <div className="form-row">
                                    <div className="input-data">
                                        <input
                                            type="text"
                                            name="national_id"
                                            value={userData.national_id}
                                            onChange={handleChange}
                                            required
                                        />
                                        <div className="underline"></div>
                                        <label>رقم الهوية </label>
                                    </div>
                                    <div className="input-data">
                                        <input
                                            type="text"
                                            name="name"
                                            value={userData.name}
                                            onChange={handleChange}
                                            required
                                        />
                                        <div className="underline"></div>
                                        <label>الاسم الرباعي </label>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="input-data">
                                        <input
                                            type="text"
                                            name="phone"
                                            value={userData.phone}
                                            onChange={handleChange}
                                            required
                                        />
                                        <div className="underline"></div>
                                        <label> رقم الجوال </label>
                                    </div>
                                    <div className="input-data">
                                        <input
                                            type="date"
                                            name="birth_date"
                                            value={userData.birth_date}
                                            onChange={handleChange}
                                            required
                                        />
                                        <div className="underline"></div>
                                        <label> تاريخ الميلاد </label>
                                    </div>
                                    <div className="input-data">
                                        <input
                                            type="email"
                                            name="email"
                                            value={userData.email}
                                            onChange={handleChange}
                                            required
                                        />
                                        <div className="underline"></div>
                                        <label> البريد الإلكتروني </label>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="input-data">
                                        <select
                                            name="location"
                                            value={userData.location}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="" disabled>
                                                مقر السكن
                                            </option>
                                            <option value="الخبر">الخبر</option>
                                            <option value="الظهران">الظهران</option>
                                            <option value="الدمام">الدمام</option>
                                            <option value="القطيف">القطيف</option>
                                        </select>
                                        <div className="underline"></div>
                                    </div>
                                    <div className="input-data">
                                        <select
                                            name="homeType"
                                            value={userData.homeType}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="" disabled>
                                                نوع السكن
                                            </option>
                                            <option value="ملك">ملك</option>
                                            <option value="إيجار">إيجار</option>
                                        </select>
                                        <div className="underline"></div>
                                    </div>
                                    <div className="input-data">
                                        <select
                                            name="roomNum"
                                            value={userData.roomNum}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="" disabled>
                                                عدد الغرف
                                            </option>
                                            <option value="2 - فأقل">2 - فأقل</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5 - فأكثر">5 - فأكثر</option>
                                        </select>
                                        <div className="underline"></div>
                                    </div>
                                </div>
                                <div className="form-row">
                                </div>
                                <div className="form-row">
                                    <div className="input-data">
                                        <input
                                            type="text"
                                            name="familyNum"
                                            value={userData.familyNum}
                                            onChange={handleChange}
                                            required
                                        />
                                        <div className="underline"></div>
                                        <label> عدد أفراد الأسرة  </label>
                                    </div>
                                    <div className="input-data">
                                        <input
                                            type="text"
                                            name="district"
                                            value={userData.district}
                                            onChange={handleChange}
                                            required
                                        />
                                        <div className="underline"></div>
                                        <label> اسم الحي  </label>
                                    </div>
                                    <div className="input-data">
                                        <input
                                            type="text"
                                            name="streetName"
                                            value={userData.streetName}
                                            onChange={handleChange}
                                            required
                                        />
                                        <div className="underline"></div>
                                        <label> اسم الشارع  </label>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="input-data">
                                        <select
                                            name="commercialRecord"
                                            value={userData.commercialRecord}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="" disabled>
                                                هل لديك سجل تجاري
                                            </option>
                                            <option value="نعم">نعم</option>
                                            <option value="لا">لا</option>
                                        </select>
                                        <div className="underline"></div>
                                    </div>
                                    <div className="input-data">
                                        <select
                                            name="shop"
                                            value={userData.shop}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="" disabled>
                                                هل لديك محلات تجارية
                                            </option>
                                            <option value="نعم">نعم</option>
                                            <option value="لا">لا</option>
                                        </select>
                                        <div className="underline"></div>
                                    </div>
                                    <div className="input-data">
                                        <select
                                            name="needs"
                                            value={userData.needs}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="" disabled>
                                                ما هي احتياجات الأسرة
                                            </option>
                                            <option value="ترميم منزل">ترميم منزل</option>
                                            <option value="سداد متعثرات فاتورة الكهرباء">سداد متعثرات فاتورة الكهرباء</option>
                                            <option value="تدريب منتهي بالتوظيف لأحد أفرادها">تدريب منتهي بالتوظيف لأحد أفرادها</option>
                                            <option value="دعم أسر منتجة">دعم أسر منتجة</option>
                                            <option value="توفير أجهزة منزلية">توفير أجهزة منزلية</option>
                                            <option value="تقوية دراسية للأبناء">تقوية دراسية للأبناء</option>
                                            <option value="التأهيل والتدريب"> التأهيل والتدريب </option>
                                            <option value="الدعم والمساندة للقدرات والتحصيلي"> الدعم والمساندة للقدرات والتحصيلي </option>
                                            <option value="كسوة العيد">كسوة العيد</option>
                                            <option value="كسوة الشتاء">كسوة الشتاء</option>
                                            <option value="السفر للعمرة أو الحج">السفر للعمرة أو الحج</option>
                                            <option value="حقيبة مدرسية">حقيبة مدرسية</option>
                                            
                                        </select>
                                        <div className="underline"></div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="input-data">
                                        <input
                                            type="text"
                                            name="job"
                                            value={userData.job}
                                            onChange={handleChange}
                                            required
                                        />
                                        <div className="underline"></div>
                                        <label> العمل الحالي </label>
                                    </div>
                                    <div className="input-data">
                                        <input
                                            type="text"
                                            name="work_address"
                                            value={userData.work_address}
                                            onChange={handleChange}
                                            required
                                        />
                                        <div className="underline"></div>
                                        <label> عنوان العمل </label>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="input-data">
                                        <input
                                            type="text"
                                            name="salary"
                                            value={userData.salary}
                                            onChange={handleChange}
                                            required
                                        />
                                        <div className="underline"></div>
                                        <label> مقدار الراتب الحالي </label>
                                    </div>
                                    <div className="input-data">
                                        <input
                                            type="text"
                                            name="socialsalary"
                                            value={userData.socialsalary}
                                            onChange={handleChange}
                                            required
                                        />
                                        <div className="underline"></div>
                                        <label> مقدار الضمان الاجتماعي </label>
                                    </div>
                                    <div className="input-data">
                                        <input
                                            type="text"
                                            name="healthStatus"
                                            value={userData.healthStatus}
                                            onChange={handleChange}
                                            required
                                        />
                                        <div className="underline"></div>
                                        <label> الحالة الصحية </label>
                                    </div>
                                </div>
                                <input
                                    type="checkbox"
                                    checked={isChecked}
                                    onChange={(e) => setIsChecked(e.target.checked)}
                                />
                                <label className="lbl-terms">
                                    <span>موافق على </span>
                                    <a
                                        className="terms-link"
                                        href="./files/Terms.pdf"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        الشروط والأحكام
                                    </a>
                                </label>
                                <div className="buttons">
                                    <button className="btn-primary">إرسال الطلب</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Beneficiaries
