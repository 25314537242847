import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "../ContactForm/ContactForm.css";
import Footer from "../Footer/Footer";

const Volu = () => {
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    name: "",
    job: "",
    national_id: "",
    birth_date: "",
    location: "",
    phone: "",
    email: "",
    membership_type: "",
    gender: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserData((prevUserData) => ({ ...prevUserData, [name]: value }));
  };
  const [isChecked, setIsChecked] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();

    if (isChecked) {
      axios
        .post("https://hm-a.net/api/vol/save", userData)
        .then(function (response) {
          Swal.fire({
            title: "صناع السعادة",
            text: "تم الإرسال بنجاح وشكرا لتواصلكم",
            icon: "success",
            confirmButtonText: "إغلاق",
            customClass: {
              title: "my-title-class",
              container: "my-content-class",
              confirmButton: "my-confirm-button-class",
            },
          }).then(() => {
            navigate("/");
          });
        })
        .catch(function (error) {
          Swal.fire({
            title: "صناع السعادة",
            text: "لم يتم إرسال الطلب بنجاح، يُرجى المحاولة في وقت آخر",
            icon: "error",
            confirmButtonText: "إغلاق",
            customClass: {
              title: "my-title-class",
              container: "my-content-class",
              confirmButton: "my-confirm-button-class",
            },
          });
        });
    } else {
      Swal.fire({
        title: "صناع السعادة",
        text: "يُرجى الموافقة على الشروط والأحكام",
        icon: "error",
        confirmButtonText: "إغلاق",
        customClass: {
          title: "my-title-class",
          container: "my-content-class",
          confirmButton: "my-confirm-button-class",
        },
      });
    }
  };
  return (
    <>
    <div className="contact">
      <div className="contact-container">
        <div className="contact-header">
          <h2 className="headfont"> سجل معنا كمتطوع </h2>

          <div className="container">
            <form className="form-control" onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="input-data">
                  <input
                    type="text"
                    name="name"
                    value={userData.name}
                    onChange={handleChange}
                    required
                  />
                  <div className="underline"></div>
                  <label>الاسم الرباعي </label>
                </div>
                <div className="input-data">
                  <input
                    type="text"
                    name="job"
                    value={userData.job}
                    onChange={handleChange}
                    required
                  />
                  <div className="underline"></div>
                  <label>المهنة </label>
                </div>
              </div>

              <div className="form-row">
                <div className="input-data">
                  <input
                    type="text"
                    name="national_id"
                    value={userData.national_id}
                    onChange={handleChange}
                    required
                  />
                  <div className="underline"></div>
                  <label>رقم الهوية </label>
                </div>

                <div className="input-data">
                  <input
                    type="date"
                    name="birth_date"
                    value={userData.birth_date}
                    onChange={handleChange}
                    required
                  />
                  <div className="underline"></div>
                  <label> تاريخ الميلاد </label>
                </div>
              </div>
              <div className="form-row">
                <div className="input-data">
                  <input
                    type="text"
                    name="location"
                    value={userData.location}
                    onChange={handleChange}
                    required
                  />
                  <div className="underline"></div>
                  <label> مكان الإقامة </label>
                </div>

                <div className="input-data">
                  <input
                    type="text"
                    name="phone"
                    value={userData.phone}
                    onChange={handleChange}
                    required
                  />
                  <div className="underline"></div>
                  <label> الجوال </label>
                </div>
                <div className="input-data">
                  <input
                    type="email"
                    name="email"
                    value={userData.email}
                    onChange={handleChange}
                    required
                  />
                  <div className="underline"></div>
                  <label> البريد الإلكتروني </label>
                </div>
              </div>
              <div className="form-row">
                <div className="input-data">
                  <select
                    name="membership_type"
                    value={userData.membership_type}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled>
                      قسم التطوع
                    </option>
                    <option value="الأعمال الإدارية"> الأعمال الإدارية</option>
                    <option value="الأعمال التنظيمية">
                      الأعمال التنظيمية
                    </option>
                    <option value="الأعمال المكتبية"> الأعمال المكتبية</option>
                    <option value="البرمجة والتصميم"> البرمجة والتصميم</option>
                  </select>
                  <div className="underline"></div>
                </div>
                <div className="input-data">
                  <select
                    name="gender"
                    value={userData.gender}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled>
                      الجنس
                    </option>
                    <option value="ذكر">ذكر</option>
                    <option value="أنثى">أنثى</option>
                  </select>
                  <div className="underline"></div>
                </div>
              </div>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
              />
              <label className="lbl-terms">
                <span>موافق على </span>
                <a
                  className="terms-link"
                  href="./files/VolTerms.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  الشروط والأحكام
                </a>
              </label>
              <div className="buttons">
                <button className="btn-primary">إرسال الطلب</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      
    </div>
    <Footer/>
    </>
    
  );
};

export default Volu;
