import React, { useState, useEffect } from "react";
// import { Button } from "./Button";
import { Link } from "react-router-dom";
import "./Navbar.css";
import logo from '../../img/logo.svg'

function Navbar() {
  const [click, setClick] = useState(false);
  // const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      // setButton(false);
    } else {
      // setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
            
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                الرئيسة
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/about"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                عن الجمعية
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/join"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                 العضوية
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/gov"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                 الحوكمة
              </Link>
            </li>
           
          


            <li className="nav-item dropdown">
          <div
            
            className="nav-links dropdown__toggle"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            خدمات المستفيدين
            
          </div>
          <ul
            className={`dropdown__menu ${showDropdown ? "show" : ""}`}
            onClick={() => setShowDropdown(false)}
          >
            <li className="dropdown__item">
            
              <Link 
              to="/services" 
              className="dropdown__link"
              onClick={closeMobileMenu}
              >
               
                مستفيد جديد
              </Link>
            </li>
            <li className="dropdown__item">
            
              <Link 
              to="/volunteer" 
              className="dropdown__link"
              onClick={closeMobileMenu}
              >
               
                تطوع معنا
              </Link>
            </li>
            <li className="dropdown__item">
            
              <Link 
              to="/contact" 
              className="dropdown__link"
              onClick={closeMobileMenu}
              >
               
                تواصل معنا
              </Link>
            </li>
          </ul>
        </li>
        



            
          </ul>
         
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                <img src={logo} width={150} alt="" />
            </Link>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
