import React from 'react';
import '../../App.css';


import Beneficiaries from '../Beneficiaries/Beneficiaries';
import Footer from '../Footer/Footer'

const Services = () => {
  return (
    <div>
      <Beneficiaries/>
      <Footer />
    </div>
  )
}

export default Services

