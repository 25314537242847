import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import '../../App.css';
import Footer from '../Footer/Footer';
import Swal from 'sweetalert2';
import { useState } from 'react';



const Contact = () => {

  const [formData, setFormData] = useState({ name: '', email: '', message:'' });

    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
    };
  
    const resetFormData = () => {
      setFormData({ name: '', email: '', message:'' }); 
    };
    

    const showAlert = () => {
      Swal.fire({
          title: 'صناع السعادة',
          text: 'تم الإرسال بنجاح وشكرا لتواصلكم',
          icon: 'success',
          confirmButtonText: 'إغلاق',
          customClass: {
            title: 'my-title-class',
            container: 'my-content-class',
            confirmButton: 'my-confirm-button-class',
          }
          
        });
      };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_d6eta2h', 'template_ws293ra', form.current, '8sYvLnID14kWAO9OW')
      .then((result) => {
          
          showAlert()
          resetFormData()



      }, (error) => {
          
          Swal.fire({
            title: 'صناع السعادة',
            text: 'لم يتم الإرسال بنجاح ،حاول مرة أخرى',
            icon: 'error',
            confirmButtonText: 'إغلاق',
            customClass: {
              title: 'my-title-class',
              container: 'my-content-class',
              confirmButton: 'my-confirm-button-class',
            }
           
          });
        
      });
  };

  return (
    <>
      <div className='contact'>
      <div className="contact-container">
          <div className="contact-header">
            <h2 className='headfont'>اتصل بنا</h2>

            <div className="container contact__container">
              <div className="contact__options">
                <article className="contact__option">
                  <div className="contact__option-icon" />
                  <h5>الجوال</h5>
                  <a href="tel://966568970073">اتصل بنا</a>
                </article>

                <article className="contact__option">
                  <div className="contact__option-icon" />
                  <h5>واتساب</h5>
                  <a
                    href="https://api.whatsapp.com/send?phone=966568970073"
                    target="_blank"
                    rel="noreferrer"
                  >
                    راسلنا
                  </a>
                </article>

                <article className="contact__option">
                  <div className="contact__option-icon" />
                  <h5>البريد الإلكتروني</h5>
                  <a href="mailto:sonna.alssaadah@gmail.com
">راسلنا</a>
                </article>
              </div>

            <form className='form-control' ref={form} onSubmit={sendEmail}>
                <div className='form-row'>

                  <div className="input-data">
                    <input type="text" name="name"  required value={formData.name} onChange={handleInputChange} />
                    <div className="underline"></div>
                      <label>الاسم </label>
                    </div>

                  <div className="input-data">
                    <input type="email" name="email" required value={formData.email} onChange={handleInputChange} />
                      <div className="underline"></div>
                    <label>البريد </label>
                  </div>
                </div>

                <div className='form-row'>
                <div className="input-data textarea">
                    <textarea rows="8" cols="80"
                    name="message"
                    required
                    value={formData.message} onChange={handleInputChange}
                  ></textarea>
                  <div className="underline"></div>
                  <label>نص الرسالة  </label>
                  </div>
                </div>
                
                
                <button type="submit" className="btn btn-primary">
                  إرسال
                </button>
              </form>

             
            </div>
          </div>
        </div>

      </div>
      <Footer />
    </>
    
  )
}

export default Contact