import React from 'react'
import Footer from '../Footer/Footer'
import NewsLine from '../NewsLine/NewsLine'


const News = () => {
  return (
    <>
    <div className='news'>
        <NewsLine />
    </div>
    <Footer/>
    </>
    
  )
}

export default News