import './NewsLine.css'
import React from 'react';
import data from '../Data/Data.json'




const NewsLine = () => {
  return (
    <>
      <div className="news-container">
        
        <div className="grid-containerr">
          {data.items.map((item, index) => (
            <div className="grid-item" key={index}>
              <div
                className="image"></div>
              <div className="separator"></div>
              <div className="text">
                <p className="p-link"><a className='p-link' href={item.link} target="_blank" rel="noreferrer">{item.text}</a></p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default NewsLine