import React, { useState } from 'react';
import './ProCard.css'


const ProCard = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedCard, setSelectedCard] = useState({});
  
    const data = [
      {
        id: 1,
        title: ' مبادرة تمكين ',
        description: ' هدف المبادرة:تأهيل أبناء وبنات الثقبة ذوي الدخل المنخفض لسوق العمل آليه التنفيذ:اقامة دورات ومهارات وخبرات وزيارات مؤهلة لسوق العمل منفريق متخصص لأبناء حي الثقبة مدة المبادرة: ٣٠ يوم المكان: جمعية صناع السعادة تكلفة المبادرة: عشرة آلاف ريال ',
        imageUrl: './images/training.jpg',
      },
      {
        id: 2,
        title: ' برودكاست السعادة ',
        description: ' هدف المبادرة:توعية المجتمع بأهمية السعادة والتفاؤل والبهجةوالسرور في حياة الفرد .آليه التنفيذ :اقامة واستضافة متخصصين في تطوير الذات وأصحاب الخبرة والمستشارين في أحد الفنادق بالخبر لتوعية المجتمع المحلي بأهمية التفاؤل والايجابية وأثر الحزن على حياة الفرد . مدة البرنامج : شهرين كل اسبوع حلقة . المكان : أحد فنادق الخبر . التكلفة: عشرون الف ريال ',
        imageUrl: './images/prodcast.jpg',
      },
      {
        id: 3,
        title: ' بيتنا سعيد ',
        description: ' هدف المبادرة:مساعدة الأسر ذوي الدخل المنخفض في اصلاح البيئة المنزلية آلية التنفيذ:اصلاح مكيف وثلاجة لكل أسرة بالتعاون مع أحد المختصينلمواجهة حر الصيف .مدة المبادرة: ٣٠ يومالمكان: بيوت الأسر ذوي الدخل المنخفض.التكلفة:خمسة عشر ألف ريال . ',
        imageUrl: './images/home.jpg',
      },
      {
        id: 4,
        title: 'فرق صناع السعادة  ',
        description: ' الهدف: صناعة فرق تطوعية للمشاركة مع الجهات الحكوميةلخدمة المجتمع المحلي.        آلية التنفيذ:       تدريب وصناعة فرق تطوعية للمساهمة في خدمة المجتمع وصناعة       السعادة للمجتمع المحلي.       مدة البرنامج: طوال العام       المكان: الجهات الحكومية.       التكلفة: عشرون ألف ريال.',
        imageUrl: './images/volli.jpg',
      },
      {
        id: 5,
        title: ' أكاديمية السعادة  ',
        description: ' الهدف :استثمار أوقات الشباب وتحسين جودة الحياة.آلية التنفيذ:انشاء أكاديمية السعادة يحتوي على ملاعب وصالات وقاعات لاستثمار أوقات الشباب وتحسين جودة الحياة. مدة البرنامج: طوال العام. المكان: يحدد لاحقا. التكلفة: مائة ألف ريال . ',
        imageUrl: './images/training.jpg',
      },
      
      
    ];
  
    function openModal(card) {
      setSelectedCard(card);
      setShowModal(true);
    }
  
    function closeModal() {
      setShowModal(false);
    }
  
    return (
      <div className="contact">
        <div className='container-p'>
        <div className="cards-container">
          {data.map((item) => (
            <div
              className="card"
              key={item.id}
              onClick={() => openModal(item)}
            >
              <img src={item.imageUrl} alt={item.title} />
              <div className="card-content">
                <h2>{item.title}</h2>
                <p> المزيد </p>
              </div>
            </div>
          ))}
        </div>
        {showModal && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={closeModal}>
                &times;
              </span>
              <img src={selectedCard.imageUrl} alt={selectedCard.title} />
              <div className="modal-card-content">
                <h2>{selectedCard.title}</h2>
                <p>{selectedCard.description}</p>
              </div>
            </div>
          </div>
        )}

        </div>
        
      </div>
    );
  }

export default ProCard