import React from 'react';
import Navbar from './components/Navbar/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Services from './components/pages/Services';
import Products from './components/pages/Products';
import About from './components/pages/About';
import Joinus from './components/pages/Joinus';
import Contact from './components/pages/Contact';
import Gov from './components/pages/Gov';
import Volu from './components/Volu/Volu';


function App() {
    return (
      <>
        <Router>
          <Navbar />
          <Routes>
            <Route path="/" exact element={<Home />}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/join" element={<Joinus/>}/>
            <Route path="/services" element={<Services/>}/>
            <Route path="/products" element={<Products/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="/volunteer" element={<Volu/>}/>
            <Route path="/gov" element={<Gov/>}/>
            
          </Routes>
        </Router>
      </>
    );
}

export default App;