import React from 'react';
import '../../components/ContactForm/ContactForm.css'
import '../../App.css'
import ContactForm from '../ContactForm/ContactForm';
import Footer from '../Footer/Footer';






const Joinus = () => {
  return (
    <>
      <ContactForm/>
      <Footer />
    </>
  );
}

export default Joinus