import React from 'react';
import '../../App.css';
import '../../fonts/all.css'
import './HeroSection.css';




function HeroSection() {
    return (
      <div className="hero-container">
        <img className='video' src='../../images/volu.jpg' alt='' />
      <div className='container header__container'>
<div className="header__socials">
            <a href="https://twitter.com/sonna_alssaadah" target="_blank" rel="noreferrer">
              <i className="fab fa-twitter"></i>
              
            </a>
            <a href="https://www.instagram.com/sonna.alssaadah" target="_blank" rel="noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.tiktok.com/@sonna.alssaadah" target="_blank" rel="noreferrer">
              <i className="fab fa-tiktok"></i>
            </a>
            <a href="https://www.facebook.com/profile.php?id=100090154570757" target="_blank" rel="noreferrer">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://youtube.com/@sonna.alssaadah" target="_blank" rel="noreferrer">
              <i className="fab fa-youtube"></i>
            </a>
          
       </div>
       <div className='sub-home'>
        <h2 className='headfont'> معا </h2> <p className='headfont'> نصنع مجتمعا  </p><span className='headfont'>سعيدا</span>
       </div>
          
      </div>
        
        
        
        </div>
       
      
    );
}

export default HeroSection;